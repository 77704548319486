import axios from "axios";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const phpAccessToken = sessionStorage.getItem("phpAccessToken");
    config.headers["Authorization"] = `Bearer ${phpAccessToken}`;
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const { status, data } = response;
    if (status >= 200 && status < 300) {
      return data;
    } else {
      return Promise.reject(new Error(data.respMsg || "Error"));
    }
  },
  error => {
    console.log(error.response.data.message); // for debug
    if (error.response.status === 401) {
      window.location.href = `${
        process.env.VUE_APP_BASE_API
      }/auth/login?redirectUrl=${encodeURIComponent(window.location.href)}`;
      return;
    }
    return Promise.reject(error);
  }
);

export default service;
