import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home")
  },
  {
    path: "/product-overview",
    name: "ProductOverview",
    component: () => import("@/views/productOverview")
  },
  {
    path: "/scenario-case",
    name: "ScenarioCase",
    component: () => import("@/views/scenarioCase")
  },
  {
    path: "/duoxing-college",
    name: "DuoXingCollege",
    component: () => import("@/views/duoXingCollege")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
