<template>
  <div>
    <div class="footer-nav">
      <div class="item">
        <p>关于多幸智能</p>
        <span>企业介绍</span>
        <span>发展历程</span>
        <span>媒体报道</span>
      </div>
      <div class="item">
        <p>多幸学院</p>
        <span>培训课程</span>
        <span>软件培训</span>
        <span>深度好文</span>
      </div>
      <div class="item">
        <p>导航菜单</p>
        <span>ISA概述</span>
        <span>场景案例</span>
        <span>认证证书</span>
      </div>

      <div class="line"></div>

      <div class="item">
        <p>联系我们</p>
        <div>
          <img src="../assets/image/phone.png" alt="" />
          <span>15001741034</span>
        </div>
        <div>
          <img src="../assets/image/email.png" alt="" />
          <span>DuoXingTech@163.com</span>
        </div>
        <div>
          <img src="../assets/image/location.png" alt="" />
          <span>上海市嘉定区槎溪路888弄</span>
        </div>
      </div>

      <div class="item" style="margin-right: 0;">
        <img src="../assets/image/qrcode.png" class="qr-code" alt="" />
      </div>
    </div>
    <div class="footer-crop">
      <p>
        <span>2021 © duoxingtech.com </span>
        <a href="https://beian.miit.gov.cn" style="color: #FFFFFF; text-decoration: none;">沪ICP备2021011914号-1</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.footer-nav {
  width: 100%;
  height: 280px;
  background: #1f3059;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-crop {
  width: 100%;
  height: 80px;
  background: #1b2b50;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;

  & p {
    font-size: 14px;
    color: #ffffff;
    opacity: 0.7;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}

.qr-code {
  width: 130px;
  height: 130px;
  margin-top: 18px;
}

.item {
  color: #ffffff;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 127px;
  font-family: PingFangSC-Semibold, PingFang SC;

  & p {
    opacity: 1;
    font-size: 16px;
    margin-bottom: 20px;
  }

  & span {
    opacity: 0.7;
    font-size: 14px;
    line-height: 28px;
  }

  & div {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
}

.line {
  width: 1px;
  height: 130px;
  background-color: #ffffff;
  margin-top: 18px;
  opacity: 0.2;
  margin-right: 127px;
}
</style>
