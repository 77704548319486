<template>
  <div class="header">
    <img src="../assets/image/logo.png" alt="" class="logo" />
    <el-tabs v-model="activeName" @tab-click="handleClickTab">
      <el-tab-pane label="首页" name="home"></el-tab-pane>
      <el-tab-pane label="产品概览" name="productOverview"></el-tab-pane>
      <el-tab-pane label="场景案例" name="scenarioCase"></el-tab-pane>
      <!--      <el-tab-pane label="多幸学院" name="duoXingCollege"></el-tab-pane>-->
    </el-tabs>
    <div class="btns">
      <div class="trial-btn" @click="goRegister">试用</div>
<!--      <div class="login-btn" @click="goLogin">登录</div>-->
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      activeName: "home"
    };
  },
  mounted() {},
  computed: {},
  watch: {
    $route: {
      handler(e) {
        switch (e.name) {
          case "Home":
            this.activeName = "home";
            break;
          case "ProductOverview":
            this.activeName = "productOverview";
            break;
          case "ScenarioCase":
            this.activeName = "scenarioCase";
            break;
          case "DuoXingCollege":
            this.activeName = "duoXingCollege";
            break;
        }
      }
    }
  },
  methods: {
    goRegister() {
      window.open("https://isa.homelybeauty.com/user/register");
    },
    goLogin() {
      window.open("https://isa.homelybeauty.com");
    },
    handleClickTab(tab) {
      switch (tab.name) {
        case "home":
          this.$router.push({
            name: "Home"
          });
          break;
        case "productOverview":
          this.$router.push({
            name: "ProductOverview"
          });
          break;
        case "scenarioCase":
          this.$router.push({
            name: "ScenarioCase"
          });
          break;
        case "duoXingCollege":
          this.$router.push({
            name: "DuoXingCollege"
          });
          break;
      }
    }
  }
};
</script>
<style scoped>
/deep/ .el-tabs__item {
  color: white;
  font-size: 16px;
  opacity: 0.6;
  font-family: PingFangSC-Medium, PingFang SC;
}

/deep/ .el-tabs__item:hover {
  color: white;
  opacity: 1;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  opacity: 1;
}

/deep/ .el-tabs__active-bar {
  height: 4px;
  background-color: #337aff;
}

/deep/ .el-tabs__nav-wrap::after {
  height: 0;
}
</style>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.logo {
  width: 203px;
  height: 36px;
  margin-left: 30px;
}

.btns {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  margin-right: 30px;
  align-items: center;
  justify-content: center;
  & :hover {
    cursor: pointer;
  }
}

.trial-btn {
  width: 76px;
  height: 36px;
  background: linear-gradient(90deg, #538dff 0%, #3179ff 100%);
  border-radius: 5px;
  line-height: 36px;
  font-size: 14px;
  color: #ffffff;
  //margin-right: 20px;
}

.login-btn {
  width: 76px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border-radius: 5px;
  border: 1px solid #ffffff;
  line-height: 36px;
  font-size: 14px;
  color: #ffffff;
}
</style>
